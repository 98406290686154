@import 'react-multi-carousel/lib/styles.css';


/* Container to center the video and handle responsive design */
.video-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the video container */
  overflow: hidden; /* Hide overflow to maintain aspect ratio */
  border-radius: 10px; /* Rounded corners for a sleek look */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
}

/* Ensure the video fills the container and maintains aspect ratio */
.video-container video {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove default inline-block spacing */
}

/* Optional: Add a play button overlay */
.video-container::before {
  content: '▶';
  font-size: 50px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  pointer-events: none; /* Make sure the button does not interfere with video controls */
}



/* Optional: For mobile responsiveness */
@media (max-width: 768px) {
  .carousel-item .col-md-4 {
    width: 100%; /* Make each card full width on small screens */
  }
}